import './MainPage.css';
import React from 'react';

export const MainPage = () => {
  return (
    <div className='main_container'>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <div className='content_block'>
          <p className='percent'>Welcome Bonus up to</p>
          <div style={{ alignItems: 'center', display: 'flex', gap: '30px' }}>
            <span className='text'>1500€</span>
            <span className='text_desc'>+150 FS</span>
          </div>
          <a
            className='App-link'
            href='https://example.com'
            target='_blank'
            rel='noopener noreferrer'
          >
            GET BONUSES
          </a>
        </div>
        <div className='image_block'>
          <img src='/img/sweet.png' className='star' alt='star' />
          <img src='/img/sweet_bg.svg' className='sweet' alt='star' />
        </div>
      </div>
      <img src='/img/path.svg' className='path' alt='path' />
      <div className='footer'>
        <span>Copyright © 2024 «FairPari».</span>
        <span>
          Fairpari.com is owned and operated by CENTRALD B.V. a company
          registered and established under the laws of Curaçao, with
          registration number 166369 and registered address at Abraham Mendez
          Chumaceiro 03, Willemstad, Curaçao and its wholly-owned subsidiary
          acting as a payment agent MATRYOTECH LTD registered in Cyprus with the
          registration number HE 457874 and registered address 5, A.G. LEVENTI,
          THE LEVENTIS GALLERY TOWER,Fl.: 13th,Apt.: 1301, NICOSIA, 1097,
          Cyprus. CENTRALD B.V. is licensed and regulated by the Curacao Gaming
          Control Board (GCB) with Licence Number OGL/2024/1143/0865. Remember
          that gambling can be addictive. Play responsibly.
        </span>
      </div>
    </div>
  );
};
