import './PageMobile.css';
import React, { useEffect, useState } from 'react';

export const PageMobile = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 601);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 601);
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return (
    <div className='mobile_container'>
      <div className='image_block_mobile'>
        <img src='/img/sweet.png' className='sweet_mobile' alt='star' />
        <img src='/img/sweet_bg.svg' className='sweet_bg_mobile' alt='star' />
      </div>
      <div className='content_block_mobile'>
        <p className='percent_mobile'>Welcome Bonus up to</p>
        <div className='bonus_block'>
          <span className='text_mobile'>1500€</span>
          <span className='text_desc_mobile'>+ 150 FS</span>
        </div>
      </div>
      <a
        className='App-link_mobile'
        href='/'
        target='_blank'
        rel='noopener noreferrer'
      >
        GET BONUSES
      </a>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <img
          src={isMobile ? '/img/path_mobile.svg' : '/img/path.svg'}
          className='path_mobile'
          alt='path_mobile'
        />
      </div>
      <div className='footer_mobile'>
        <span>Copyright © 2024 «FairPari».</span>

        <span>
          Fairpari.com is owned and operated by CENTRALD B.V. a company
          registered and established under the laws of Curaçao, with
          registration number 166369 and registered address at Abraham Mendez
          Chumaceiro 03, Willemstad, Curaçao and its wholly-owned subsidiary
          acting as a payment agent MATRYOTECH LTD registered in Cyprus with the
          registration number HE 457874 and registered address 5, A.G. LEVENTI,
          THE LEVENTIS GALLERY TOWER,Fl.: 13th,Apt.: 1301, NICOSIA, 1097,
          Cyprus. CENTRALD B.V. is licensed and regulated by the Curacao Gaming
          Control Board (GCB) with Licence Number OGL/2024/1143/0865. Remember
          that gambling can be addictive. Play responsibly.
        </span>
      </div>
    </div>
  );
};
